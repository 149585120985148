import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController ,AlertController  } from '@ionic/angular';


import { UserData } from '../../services/user-data';
import { UserAccountService } from '../../services/user-account-service';
import {MerchantService} from '../../services/merchant-service'
import {ShareDataService} from "../../services/shareData-service";

@Component({
  selector: 'app-merchant-schedule-list',
  templateUrl: './merchant-schedule-list.component.html',
  styleUrls: ['./merchant-schedule-list.component.scss'],
})
export class MerchantScheduleListComponent implements OnInit {

  scheduleList :any;
  regularSchedule: any;
  merchant: any;
  merchantLocation: any
  scheduleType: any = 'daily'
  currentLocation: any;


  weekDayMap: Map<string,any> = new Map<string, any>();
  merchantScheduleMap:Map<string,any> = new Map<string, any>();


  constructor(public userData: UserData,public modalController: ModalController ,
              public navParams: NavParams, public modalCtrl: ModalController,public shareDataService: ShareDataService,
              public alertCtrl: AlertController,public merchantService : MerchantService,
              public userAccountService: UserAccountService){

  }

  ngOnInit() {

    this.weekDayMap.set('Monday',0);
    this.weekDayMap.set('Tuesday',1);
    this.weekDayMap.set('Wednesday',2);
    this.weekDayMap.set('Thursday',3);
    this.weekDayMap.set('Friday',4);
    this.weekDayMap.set('Saturday',5);
    this.weekDayMap.set('Sunday',6);

    this.merchantScheduleMap.set('Monday',[]);
    this.merchantScheduleMap.set('Tuesday',[])
    this.merchantScheduleMap.set('Wednesday',[])
    this.merchantScheduleMap.set('Thursday',[])
    this.merchantScheduleMap.set('Friday',[])
    this.merchantScheduleMap.set('Saturday',[])
    this.merchantScheduleMap.set('Sunday',[])

    this.merchant = this.navParams.get("merchant");
    this.merchantLocation = this.navParams.get("merchantLocation");

    this.userData.getCurrentSearchLocation().then(v=>{
      if(v != null){
        this.currentLocation = JSON.parse(v);
        console.log('location is ', this.currentLocation);
      }
    });
    let s = this.merchantService.getMerchantFutureScheduleByDate(this.merchant.id,this.merchantLocation.id,'2018-07-03').subscribe(v=>{
      if(v != null){
        this.scheduleList = v;
      }
      s.unsubscribe();
    })

    for(const day of this.getKeys(this.weekDayMap)){
      const s1 = this.merchantService.getMerchantRegularSchedule(this.merchant.id,this.merchant.locationList[0],day).subscribe(v=>{
        s1.unsubscribe();
        if(v != null && v.length > 0){
          this.scheduleType = 'regular'
          console.log('schedule is ', v);
          this.merchantScheduleMap.set(day,v);
        }
      });
    }


  }

  getDate(dateStr):any{
    return Date.parse(dateStr);
  }

  getKeys(map) : any{
    return Array.from(map.keys());
  }

  getValues(map) : any{
    return Array.from(map.values());
  }


  dismiss() {
    this.modalController.dismiss();
  }

}
